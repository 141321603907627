<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
        >New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add a New Field</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p>Once added, a field cannot be removed from your schema.</p>
              <div
                :class="`alert alert-${message.color}`"
                role="alert"
                v-if="message.show"
              >
                {{ message.text }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="model.name"
                :disabled="model.id > 0"
                required
                label="Field name"
                :rules="[v => !!v || 'Item is required']"
                ref="f_name"
                hint="Field names can only contain lowercase letters, numbers, and underscores"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                label="Field type (at elastic)"
                ref="f_type_id"
                item-text="name"
                item-value="id"
                :rules="[v => !!v || 'Item is required']"
                v-model="model.type_id"
                :items="types"
                required
              ></v-select>
              <div v-if="model.temp_type_id && model.temp_type_id.name !== ''">
                Ready for next update:
                <strong>{{ model.temp_type_id.name }}</strong>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Unique field"
                ref="f_unique_field"
                v-model="model.unique_field"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Array has one key"
                ref="f_one_key"
                v-model="model.one_key"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Use as front"
                ref="f_front_name"
                v-model="model.front_name"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Archive item"
                ref="f_archive"
                v-model="model.archive"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Autocomplete"
                ref="f_autocomplete"
                v-model="model.autocomplete"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                label="Custom data"
                ref="f_custom_data"
                v-model="model.custom_data"
              ></v-switch>
            </v-col>
          </v-row>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showDialog = false"
            >Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  CLOSE_DIALOG,
  POST_SCHEMA,
  PULL_SCHEMA_TYPES,
  PULL_SCHEMAS,
  PUT_SCHEMA,
  REFRESH
} from "@/core/services/store/schema.module";
import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";

export default {
  data: () => ({
    valid: true,
    dialog: false
  }),
  methods: {
    refresh() {
      this.name = "";
    },
    save() {
      // send register request
      if (this.$refs.form.validate()) {
        if (this.model.id === 0) {
          this.$store
            .dispatch(POST_SCHEMA, {
              name: this.$refs.f_name.$refs.input.value,
              type_id: this.model.type_id.id
                ? this.model.type_id.id
                : this.model.type_id,
              unique_field: this.model.unique_field ? 1 : 0,
              one_key: this.model.one_key ? 1 : 0,
              front_name: this.model.front_name ? 1 : 0,
              autocomplete: this.model.autocomplete ? 1 : 0,
              custom_data: this.model.custom_data ? 1 : 0,
              collection_id: this.collectionSelected.id
            })
            .then(() => {
              this.dialog = false;
              this.show_dialog = false;
              this.$store.dispatch(PULL_SCHEMAS, this.collectionSelected.id);
              this.$store.dispatch(CLOSE_DIALOG);
            });
        } else {
          this.$store
            .dispatch(PUT_SCHEMA, {
              id: this.model.id,
              data: {
                name: this.$refs.f_name.$refs.input.value,
                type_id: this.model.type_id.id
                  ? this.model.type_id.id
                  : this.model.type_id,
                unique_field: this.model.unique_field ? 1 : 0,
                one_key: this.model.one_key ? 1 : 0,
                front_name: this.model.front_name ? 1 : 0,
                archive: this.model.archive ? 1 : 0,
                autocomplete: this.model.autocomplete ? 1 : 0,
                custom_data: this.model.custom_data ? 1 : 0,
                collection_id: this.collectionSelected.id
              }
            })
            .then(() => {
              this.dialog = false;
              this.show_dialog = false;
              this.$store.dispatch(PULL_SCHEMAS, this.collectionSelected.id);
              this.$store.dispatch(CLOSE_DIALOG);
            });
        }
      }
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    showDialog: {
      get: function() {
        return this.dialog || this.show_dialog;
      },
      set: function(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    ...mapState({
      errors: state => state.schema.errors,
      message: state => state.message.message,
      types: state => state.schema.types,
      model: state => state.schema.model,
      collectionSelected: state => state.collections.collectionSelected,
      show_dialog: state => state.schema.showDialog
    }),
    ...mapGetters(["selectedCollection"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
    this.$store.dispatch(RELOAD_MESSAGE);
    this.$store.dispatch(PULL_SCHEMA_TYPES);
  }
};
</script>
